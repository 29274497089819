<template>
   <div class="paper" style="left: 0;width: 100%;padding: 0!important;">
      <PaperHeader/>
      <div class="smart-wrap dash"  style="padding: 0 20px;" v-if=" organizationData.app == 'DLV'" >
         <div class="col-md-12 options" style="padding: 20px 0;" >
            <h1>{{ data.RES.attributes['responsible.name'].attributeValue}}</h1>
            <p >Email: {{ data.RES.attributes['responsible.email'].attributeValue }}</p>
            <ui-accion-button action="refresh" v-on:click="getList()"/>
         </div>
         <div class="col-md-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
               <li class="nav-item" v-for="(list, index) in data.Tables" :key="index">
                  <a :class="{'nav-link':true, 'active': index == 0}" :id="list.Title+'-tab'" v-on:click="list.subscribe = false" data-toggle="tab" :href="'#'+list.Title" role="tab" :aria-controls="list.Title" aria-selected="true">
                     <i v-if="list.subscribe " class='bx bxs-star' style='color:#0085ff'  ></i>
                     {{ list.Title }}
                  </a>
               </li>
            </ul>
            <div class="tab-content" id="myTabContent">
               <div v-for="(list, index) in data.Tables" :key="index" :class="{'tab-pane fade show':true, 'active': index == 0 }" style="padding:10px 0;" :id="list.Title" role="tabpanel" :aria-labelledby="list.Title+'-tab'">
                  <div class="list-group"   style="width: 100%;" >
                     <div href="#" class="list-group-item list-group-item-action " v-for="(record, rIndex) in list.data" :key="rIndex"  >
                        <div class="smart-wrap col-md-12"  style="justify-content: space-between;    flex-wrap: wrap;">
                           <span v-if="record.subscribe">
                              <i v-if="record.subscribe == 'edit'" class='bx bxs-pencil' style='color:#5ad911'></i>
                              <i v-if="record.subscribe == 'new'" class='bx bxs-star' style='color:#0085ff'  ></i>
                           </span>
                           <div class="col-md-12 " style="display: flex;align-items: center; justify-content: space-between; background: #e1e1e1;padding: 10px; margin: 0 0 10px;" > 
                              <div>
                                 # <strong>{{ record.friendlyId }} </strong> - <span >{{ record.attributes['orderNumber'].attributeValue }} </span><br>
                                 <span style="font-style: italic;"> {{ formatData('date',record.attributes['date'].attributeValue)[0]}}</span>
                              </div>
                              <div class="absoluteCenterFlex" >
                                 <UiButton v-if="index==0" text="Take" v-on:click="attUpdate(record, 'take')"/>
                                 <UiButton v-if="index==1 && record.attributes['status'].attributeValue != 'Delivered'" text="Release" style="margin: 0 5px;"  v-on:click="attUpdate(record, 'release')"/>
                                 <UiButton v-if="index==1 && record.attributes['status'].attributeValue != 'Delivered'" text="Delivered" style="margin: 0 5px;"  v-on:click="attUpdate(record, 'delivered')"/>
                              </div>
                           </div>
                           <p class="col-md-12 col-text" > 
                              <span > {{ formatData('date',record.attributes['date'].attributeValue)[0]}}</span>
                           </p>
                           <p class="col-md-12 col-text" > 
                              <strong> Client : </strong> 
                              <span > {{ record.attributes['sml.contactFullName'].attributeValue['contact.salutation'] +' '+record.attributes['sml.contactFullName'].attributeValue['contact.firstName']+' '+record.attributes['sml.contactFullName'].attributeValue['contact.lastName']}} </span>
                           </p>
                           <p class="col-md-12 col-text" v-if="record.attributes['SMLPhones'].attributeValue[0]" >   
                              <span >{{ formatData( 'phone', record.attributes['SMLPhones'].attributeValue[0].phoneNumber) }}</span>
                           </p>
                           <p class="col-md-12 col-text" v-if="record.attributes['SMLEmails'].attributeValue[0]" > 
                              <span >{{ record.attributes['SMLEmails'].attributeValue[0]['contact.email']}}</span>
                           </p>
                           <p class="col-md-12 col-text" > 
                              <span >{{ record.attributes['contact.streetAddress'].attributeValue }}</span>
                              <span >&nbsp;{{ record.attributes['contact.city'].attributeValue }}</span>
                              <span >&nbsp;{{ record.attributes['contact.state'].attributeValue }}</span>
                              <span >&nbsp;{{ record.attributes['contact.zipCode'].attributeValue }}</span>
                           </p>
                           <p class="col-md-12 col-text" > 
                              <strong>{{ record.attributes['provider'].label }}: </strong> 
                              <span > {{ record.attributes['provider'].attributeValue.value }} </span>
                           </p>
                           <p class="col-md-12 col-text" > 
                              <span > {{ record.attributes['provider.phoneNumber'].attributeValue }} </span>
                           </p>
                           <p class="col-md-12 col-text" > 
                              <span >{{ record.attributes['provider.streetAddress'].attributeValue }}</span>
                              <span >&nbsp;{{ record.attributes['provider.city'].attributeValue }}</span>
                              <span >&nbsp;{{ record.attributes['provider.state'].attributeValue }}</span>
                              <span >&nbsp;{{ record.attributes['provider.zipCode'].attributeValue }}</span>
                           </p>
                           <p class="col-md-12 col-text" > 
                              <strong>{{ record.attributes['status'].label }}: </strong> 
                              <span > {{ record.attributes['status'].attributeValue }} </span>
                           </p>
                           <DisplayProducts :list="record.attributes.products.attributeValue" />
                           <div class="list-group col-md-12" v-if="record.attributes['pictures'].attributeValue.length != 0">
                              <div class="col-md-12">
                                 <img :src="img.url" style="width: 25%;padding:5px;" alt="" v-for="(img, index) in record.attributes['pictures'].attributeValue" :key="index">
                              </div>
                           </div>
                           <div class="list-group-item list-group-item-action" v-on:click="setgRecord([record, 'out', 'driver']);"  data-toggle="modal" data-target="#modal-file">
                              Add Picture
                           </div>
                           <p class="col-md-12 col-text" > 
                              <strong>{{ record.attributes['notes'].label }}: </strong> 
                              <span > {{ record.attributes['notes'].attributeValue }} </span>
                           </p>
                           <div class="list-group col-md-12">
                              <div class="list-group-item list-group-item-action" v-on:click="setgRecord([record, 'out']);"  data-toggle="modal" data-target="#modal-notes">
                                 Add Notes
                              </div>
                           </div>
                           
                        </div>
                        <div class="collapse " :id="'collapse-'+rIndex" >
                           <div class="input-wrap" v-for="(input, x) in 5" :key="x">
                              <label for="">Label</label>
                              <input type="text" placeholder="attributeValue">
                           </div>
                           <a href="" class="btn-g show blue" style="width: 200px;  display: block; padding: 10px 0; margin: 20px auto;">
                              Save
                           </a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         
         </div>
      </div>
      <paper-modal-custom/>
   </div>
</template>
<script>
let tokenQuote = null
   //AWS - GRAPHQL
   import { smd_getWebPage, smd_getRecordIndex, smd_getWorkerDash, smd_getRecordObj   } from "../../../graphql/queries";
   import { onNotify, smd_savePaymentFormContact, smd_updateAttributes  } from '../../../graphql/mutations'
   import { onUpdateSmartRecord, onUpdateRecord, onSetItemState, onUpdateAttributes } from '../../../graphql/subscriptions'
   //Tools
   import Vuex from "vuex";
   import { Auth, API,Storage } from "aws-amplify";
   import "amazon-connect-streams";
   import draggable from "vuedraggable";
   import Swal from 'sweetalert2'
   import { loading, popUp, formatData  } from "../../../store/tools";

   //Components
   import PaperDisplayer from "../../../components/papers/PaperDisplayer.vue";
   import PaperLayout from "../../../components/papers/PaperLayout.vue";
   import PaperUser from "../../../components/papers/PaperUser.vue";
   import PaperModalCustom from "../../../components/papers/PaperModalCustom.vue";
   
   import UiTable from "../../../components/ui/UiTable.vue";
   import UiButton from "../../../components/ui/UiButton.vue";
   import UiAccionButton from "../../../components/ui/UiAccionButton.vue";

   import DisplayProducts from "../Components/DisplayProducts.vue"
   import PaperHeader from "../Components/PaperHeader.vue"

   export default {
      components: {
         PaperDisplayer,
         PaperLayout,
         UiTable,
         PaperUser,
         PaperModalCustom,
         UiAccionButton,
         UiButton,
         DisplayProducts,
         PaperHeader
      },
      data() {
         return {
            //Record
            list:[],
            filterTable:[],
            filterList:[],
            filter:'',
            data: undefined,
            sml:'',
            hostUrl:'',
            webSite: undefined,
            renderWeb: false,
            widthDisplayWebSite:'100%',
            //
            tandc: false,
            saveCard: false,
            
            methodPayment:'',
         };
      },
      created() {
         this.hostUrl = location.origin
         loading('show', 'webPreview')
         this.setUrlHistory('driver')
         if ( this.organizationID == 'Not authorized') {
            this.$router.push({
               path: "/login",
            });
         } else {
            this.getList()
            this.subscribe()
         }
      },
      methods: {
         ...Vuex.mapMutations([
            'setWebSite',
            'SetOrganizationID',
            'SetOrganizationData',
            'setPublicOrganization',
            'setPublicOrganizationData',
            'setgRecord',
            'setUrlHistory'
         ]),
         ...Vuex.mapActions([
            'getPublicOrganization'
         ]),
         formatData(format, data){
            return formatData(format, data)
         },
         //Init
         async setUserWeb(){
            Auth.signIn('smd_webuser', '383@JDU45ss#')
            .then((user) => { 
               //Clear Global Variables
               this.SetOrganizationID("Not authorized")
               this.SetOrganizationData("Not authorized")
               this.setPublicOrganization(undefined)
               this.setPublicOrganizationData(undefined)
               //console.clear()
               ///-----
               //this.getPublicOrganization()
               setTimeout(() => {
                  this.getSML()
                  //this.getList()
               }, 1500);
              //this.loading('hidden')
            })
            .catch((err) => console.log(err))
         },
         async getList(filter){
            //console.clear()
            loading('show', 'webPreview')
            let pushData = {};
            let pullData = ''
            try {
               console.log('this.attUser',this.attUser);
               pushData = {
                  PK: this.organizationID,
                  SK: this.attUser['custom:responsibleSK']//'USR#driver2'//this.attUser['custom:GSP1PK1'],
               }
               console.log('pushData Get Layout:', pushData);
               pullData = await API.graphql({
                  query: smd_getWorkerDash,
                  variables: pushData
               });
               pullData = pullData.data.smd_getWorkerDash
               pullData = JSON.parse(pullData.data)
               console.log(pullData);
               let data = pullData
               for (let i = 0; i <  data.Tables.length; i++) {
                  for (let j = 0; j < data.Tables[i].data.length; j++) {
                     if (!this.filterList.includes(data.Tables[i].data[j].attributes.status.attributeValue) && data.Tables[i].data[j].attributes.status.attributeValue != 'Unassigned' && data.Tables[i].data[j].attributes.status.attributeValue != '' ) {
                        this.filterList.push(data.Tables[i].data[j].attributes.status.attributeValue);
                     }
                  }
               }
               this.data = data
               loading('hide', 'webPreview')
            } catch (error) {
               console.log(error);
               popUp(error, 'cach', 'err')
            }
            loading('hide', 'webPreview')
         },
         async logOut() {
            loading('show')
            //this.clear()
            try {
            await Auth.signOut();
            setTimeout(() => {
               console.log("salir");
               this.$router.push({
                  path: "/login",
               });
               loading('hide')
            }, 1000);
            } catch (error) {
            //console.log(error);
            loading('hide')
            Swal.fire({
               icon: 'error',
               title: 'Something went wrong!' ,
               text: error,
               footer: 'smd_getMyOrganization'
            })
            }
         },
         //page
         setValue(data){
            let value = data
            try {
               //value = JSON.parse(value).value
            } catch (error) {
               
            }
            return value
         },
         async subscribe() {
            API.graphql({  query: onUpdateSmartRecord, variables: { PK: this.organizationID }  }).subscribe({
               next: (eventData) => {
                  try {
                     let pullData = eventData.value.data.onUpdateSmartRecord;
                     let subRecord =JSON.parse(pullData.data)
                     //Process subRecord
                     //for (const key in subRecord.attributes) {
                        //subRecord[key] = subRecord.attributes[key].attributeValue
                     //}
                     //subRecord = recordToDisplay(subRecord)
                     //-- Sub in table
                     let exist = false
                     console.log(subRecord);
                     if (subRecord.PK == this.organizationID   ) {
                        console.log('** NEW SUB: '+ subRecord.shortEntity ,subRecord);
                        for (let i = 0; i <  this.data.Tables.length; i++) {
                           for (let j = 0; j < this.data.Tables[i].data.length; j++) {
                              if (this.data.Tables[i].data[j].SK == subRecord.SK) {
                                 this.data.Tables[i].data.splice(j, 1)
                                 subRecord.subscribe = 'edit'
                                 exist = true
                                 if ( this.data.RES.SK == subRecord.attributes.responsible.attributeValue.SK) {
                                    this.data.Tables[1].data.splice(0, 0, subRecord)
                                    this.data.Tables[1].subscribe = true
                                 }
                                 if( !subRecord.attributes.responsible.attributeValue.SK ){
                                    this.data.Tables[0].data.splice(0, 0, subRecord)
                                    this.data.Tables[0].subscribe = true
                                 }
                                 //this.data.Tables[i].data[j] = subRecord
                              }
                           }
                        }
                        if (exist == false) {
                           subRecord.subscribe = 'new'
                           if (!subRecord.attributes.responsible.attributeValue.SK) {
                              this.data.Tables[0].data.splice(0, 0, subRecord)
                              this.data.Tables[0].subscribe = true
                           }
                           else if (this.data.RES.SK == subRecord.attributes.responsible.attributeValue.SK) {
                              this.data.Tables[1].data.splice(0, 0, subRecord)
                              this.data.Tables[1].subscribe = true
                           }
                        }
                     }else{
                        if (subRecord.PK == '#SMARTDASH' && this.userPermissions.includes('Superadmin')) {
                        //console.log('** NEW SUB ADMIN: '+ subRecord.shortEntity ,subRecord);
                        
                        }else{
                        //console.log('no son iguales')
                        }
                     }
                  } catch (error) {
                     console.log(error);
                  }
               }
            });

            API.graphql({  query: onUpdateAttributes  }).subscribe({
               next: (eventData) => {
                  try {
                     let pullData = eventData.value.data.onUpdateAttributes;
                     let subRecord =JSON.parse(pullData.data)
                     let exist = false
                     console.log(subRecord);
                     if (subRecord.PK == this.organizationID   ) {
                        console.log('** NEW SUB: '+ subRecord.shortEntity ,subRecord);
                        for (let i = 0; i <  this.data.Tables.length; i++) {
                           for (let j = 0; j < this.data.Tables[i].data.length; j++) {
                              if (this.data.Tables[i].data[j].SK == subRecord.SK) {
                                 this.data.Tables[i].data.splice(j, 1)
                                 subRecord.subscribe = 'edit'
                                 exist = true
                                 if ( this.data.RES.SK == subRecord.attributes.responsible.attributeValue.SK) {
                                    this.data.Tables[1].data.splice(0, 0, subRecord)
                                    this.data.Tables[1].subscribe = true
                                 }
                                 if ( !subRecord.attributes.responsible.attributeValue.SK){
                                    this.data.Tables[0].data.splice(0, 0, subRecord)
                                    this.data.Tables[0].subscribe = true
                                 }
                                 //this.data.Tables[i].data[j] = subRecord
                              }
                           }
                        }
                        if (exist == false) {
                           subRecord.subscribe = 'new'
                           if (!subRecord.attributes.responsible.attributeValue.SK) {
                              this.data.Tables[0].data.splice(0, 0, subRecord)
                              this.data.Tables[0].subscribe = true
                           }
                           else if (this.data.RES.SK == subRecord.attributes.responsible.attributeValue.SK) {
                              this.data.Tables[1].data.splice(0, 0, subRecord)
                              this.data.Tables[1].subscribe = true
                           }
                        }
                     }else{
                        if (subRecord.PK == '#SMARTDASH' && this.userPermissions.includes('Superadmin')) {
                        //console.log('** NEW SUB ADMIN: '+ subRecord.shortEntity ,subRecord);
                        
                        }else{
                        //console.log('no son iguales')
                        }
                     }
                  } catch (error) {
                     console.log(error);
                  }
               }
            });

            API.graphql({ query: onSetItemState }).subscribe({
            next: (eventData) => {
               let pullData = eventData.value.data.onSetItemState;
               let subRecord =JSON.parse(pullData.data)
               //Process subRecord
               //for (const key in subRecord.attributes) {
                  //subRecord[key] = subRecord.attributes[key].attributeValue
               //}
               //subRecord = recordToDisplay(subRecord)
               //-- Sub in table
               let exist = false
               console.log(subRecord);
               if (subRecord.PK == this.organizationID   ) {
                  console.log('** NEW SUB: '+ subRecord.shortEntity ,subRecord);
                  for (let i = 0; i <  this.data.Tables.length; i++) {
                     for (let j = 0; j < this.data.Tables[i].data.length; j++) {
                        if (this.data.Tables[i].data[j].SK == subRecord.SK) {
                           subRecord.subscribe = 'edit'
                           //exist = true
                           this.data.Tables[i].data= splice(j, 1)
                        }
                     }
                  }
               }else{
                  if (subRecord.PK == '#SMARTDASH' && this.userPermissions.includes('Superadmin') ) {
                  //console.log('** NEW SUB ADMIN: '+ subRecord.shortEntity ,subRecord);
                  
                  }else{
                  //console.log('no son iguales')
                  }
               }
               setTimeout(() => { this.renderComponent = true }, 100);
            }
            });
            
         },
         //Edit
         async attUpdate(data, action){
            loading('show', 'getItem')
            let pullData = ''
            let pushData = ''
            let att = []
            console.log(data.attributes.status.attributeValue);
            switch (action) {
               case 'take':   
                  att =[
                     { 
                        attributeName: "responsible", 
                        attributeType: "L", 
                        attributeValue: JSON.stringify({PK: this.organizationID, SK: this.attUser['custom:responsibleSK'], value: this.data.RES.attributes['responsible.name'].attributeValue, id: this.user}), 
                     },
                  ]
               break;
               case 'release':   
                  att =[
                     { 
                        attributeName: "responsible", 
                        attributeType: "L", 
                        attributeValue: [], 
                     },
                  ]
               break;
               case 'delivered':   
                  att =[
                     { 
                        attributeName: "status", 
                        attributeType: "S", 
                        attributeValue: "Delivered", 
                     },
                  ]
               break;
            }
            pushData={
               PK: data.PK,   
               SK: data.SK ,
               attributes: JSON.stringify(att) ,
            }
            console.log('pushData: ',pushData);
            try {
               pullData = await API.graphql({
                  query: smd_updateAttributes, //smd_updateSmartRecord
                  variables: { 
                     PK: data.PK,
                     SK: data.SK ,
                     attributes: JSON.stringify(att) ,
                  },
               }
            );
            console.log(pullData);
            this.getList()
            } catch (error) {
               console.log(error);
               popUp(error, 'cach', 'err')
            }
            loading('hidde', 'getItem')
         }, 
      },
      computed: {
         ...Vuex.mapState([
            "organizationID",
            "publicOrganization",
            "attUser",
            "publicOrganizationData",
            "organizationData",
            'user',
            "dataUser",
            "userPermissions",
            'publicOrgPay'
         ]),
      },
      watch: {
         
      },
   };

</script>
